



















import { api } from '@/services/api.service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DashboardView extends Vue {
  introVideo: string|null = null

  get isMuted (): boolean {
    return this.$store.state.isMuted
  }

  async fetchGameInfo () {
    const { data } = await api.request.get('/info')

    if (data.video) {
      this.introVideo = data.video
    } else {
      this.redirectToQuestionRoute()
    }
  }

  redirectToQuestionRoute () {
    this.$router.replace({ name: 'question' })
  }

  mounted () {
    this.fetchGameInfo()
  }
}
